import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;
    const bio2 = this.props.data.bio2;
    const linkedIn = this.props.data.linkedIn;
    const phone = this.props.data.phone;
    const email = this.props.data.email;
    const resumeDownload = this.props.data.resumedownload;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={profilepic}
                alt="Yi Li Profile"
              />
            </div>
            <div className="nine columns main-col">
              <h2>About Me</h2>
              <p>{bio}</p>
              <p>{bio2}</p>
              <div className="row">
                <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
                    <span>
                      {name}<br />
                      <a href={linkedIn} target="_blank" rel="noreferrer">LinkedIn</a><br />
                      Mobile: {phone}<br />
                      Email: {email}<br />
                      {this.props.data.address.line1}<br />
                      {this.props.data.address.line2}<br />
                      {this.props.data.address.line3}<br />
                    </span>
                  </p>
                </div>
                <div className="columns download">
                  <p>
                    <a href={resumeDownload} target="_blank" rel="noreferrer" className="button">
                      <i className="fa fa-download"></i>Download Resume
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
